import React, { useEffect, useState } from "react";
import Traec from "traec";
import { BSCard, BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { AddFormField } from "AppSrc/forms/fields";
import { ErrorBoundary } from "traec-react/errors";
import { v4 as uuidv4 } from "uuid";
import { HTMLText } from "traec/utils/html";

import FieldMultiSelectConnected, { isApiField } from "./apifield";

const saveMeta = (saveMetaFetchProps, meta, setPending) => {
  let { handler, method, params, successHook, failureHook } = saveMetaFetchProps;
  console.log("Saving meta_json", saveMetaFetchProps, Traec.Im.isImmutable(meta) ? meta.toJS() : meta);
  let fetch = new Traec.Fetch(handler, method, params);
  fetch.updateFetchParams({
    preFetchHook: body => ({
      meta_json: meta
    }),
    postSuccessHook: data => {
      setPending(false);
      if (successHook) {
        successHook(data);
      }
    },
    postFailureHook: data => {
      setPending(false);
      if (failureHook) {
        failureHook(data);
      }
    }
  });
  fetch.dispatch();
};

const pushMeta = (pushMetaFetchProps, fieldName, includeValue) => {
  let { handler, method, params } = pushMetaFetchProps;
  console.log("Pushing meta_json", pushMetaFetchProps, fieldName, includeValue);
  let fetch = new Traec.Fetch(handler, method, params);
  fetch.updateFetchParams({
    preFetchHook: body => ({
      type: "PUSH_META_FORM_FIELD_TO_CHILDREN",
      payload: { ...params, fieldName, includeValue }
    })
  });
  fetch.dispatch();
};

function FieldSelect({ uid, field, value, errors, disabled, onChangeHandler }) {
  let header = field.get("header");
  let options = field.get("options") || Traec.Im.List();
  let error = false; //errors.get(header);

  let _options = options.unshift(null).map((option, i) => (
    <option key={i} value={option}>
      {option}
    </option>
  ));

  return (
    <select
      type="text"
      className={`form-control ${error ? "is-invalid" : ""}`}
      id={uid}
      name={header}
      value={value}
      onChange={onChangeHandler}
      disabled={disabled}
    >
      {_options}
    </select>
  );
}

function FieldMultiSelect({ field, value, disabled, onChangeHandler }) {
  let options = (field.get("options") || Traec.Im.List())
    .toJS()
    .map(i => (typeof i === "string" ? { value: i, label: i } : i));

  let valueSet = new Traec.Im.Set(value || []);
  console.log("RENDERING FieldMultiSelect", valueSet.toJS(), options);

  return (
    <Select
      isClearable={true}
      isMulti={true}
      placeholder="Start typing to search..."
      onChange={data => {
        let value = data ? data.map(i => i.value) : null;
        console.log("Handling change to value", value, data);
        onChangeHandler({ target: { value } });
      }}
      options={options}
      defaultValue={(options || []).filter(i => valueSet.has(i.value))}
      isDisabled={disabled}
    />
  );
}

const getInputClass = error => "form-control" + (error ? " is-invalid" : "");

function FieldInput({ uid, value, onChangeHandler, disabled, error, type }) {
  return (
    <React.Fragment>
      <input
        id={uid}
        className={getInputClass(error)}
        value={value || ""}
        onChange={onChangeHandler}
        disabled={disabled}
        type={type == "number" ? "number" : "text"}
      />
      {error ? <div className="invalid-feedback">{error}</div> : null}
    </React.Fragment>
  );
}

const moveListItem = (list, index, incr) => {
  return list.delete(index).insert(index + incr, list.get(index));
};

const TYPE_MAP = {
  selection: FieldSelect,
  multiselection: FieldMultiSelect,
  number: FieldInput
};

const getFieldComponent = type => {
  return TYPE_MAP[type] || FieldInput;
};

function MetaFormFieldDropdown({ pushHandler, header, fields, setFields, index, hide }) {
  if (hide) {
    return null;
  }
  let links = [];
  if (pushHandler) {
    links = links.concat([
      { name: "Push field and value to children", onClick: e => pushHandler(e, header, true) },
      { name: "Push field-only to children", onClick: e => pushHandler(e, header, false) },
      {}
    ]);
  }
  // These dropdowns are for everyone
  links = links.concat([
    { name: "Move up", onClick: e => setFields(moveListItem(fields, index, -1)) },
    { name: "Move down", onClick: e => setFields(moveListItem(fields, index, 1)) },
    {},
    {
      name: "Delete",
      onClick: e => {
        setFields(fields.delete(index));
      }
    }
  ]);
  return <BSBtnDropdown header={" "} links={links} />;
}

/* Render a single row of the form */

const isValidateField = (type, value) => {
  if (type == "number" && isNaN(value)) {
    return "Input must be a number!";
  }
  return "";
};

function MetaFormField(props) {
  let { field, meta, setMeta, hideAdmin, disabled, saveOnBlur, onSaveHandler, values, setValues } = props;
  let header = field.get("header");
  let value = meta.get(header);
  let type = field.get("type");
  let description = field.get("description");

  let [error, setError] = useState("");

  const onChangeHandler = e => {
    let _error = isValidateField(type, e.target.value);
    if (_error) {
      setError(_error);
    } else {
      setValues(values.set(header, e.target.value));
      setMeta(meta.set(header, e.target.value));
    }
  };

  let uid = `${uuidv4()}`;
  let FieldInputComponent = getFieldComponent(type);

  // Add a special
  if (isApiField(header)) {
    FieldInputComponent = FieldMultiSelectConnected;
  }

  return (
    <div className="form-group">
      <label htmlFor={uid}>
        {header}
        <MetaFormFieldDropdown {...props} header={header} hide={hideAdmin} />
      </label>
      <div onBlur={saveOnBlur ? onSaveHandler : null}>
        <FieldInputComponent
          uid={uid}
          field={field}
          value={value}
          disabled={disabled}
          onChangeHandler={onChangeHandler}
          error={error}
          type={type}
        />
      </div>
      {description ? (
        <small>
          <HTMLText text={description} />
        </small>
      ) : null}
    </div>
  );
}

const employeeSizeMap = {
  "<10 employees": [1, 9],
  "<50 employees": [1, 49],
  "<250 employees": [1, 249],
  "<500 employees": [1, 499],
  "<1000 employees": [1, 999],
  ">1000 employees": [1, 1500000]
};

const isEmployeeSizeDataValid = (coverageOfDataValue, employeeSizeValue) => {
  let minMaxValues = employeeSizeMap[employeeSizeValue];
  if (coverageOfDataValue == null || minMaxValues == null) {
    return true;
  }
  return coverageOfDataValue >= minMaxValues[0] && coverageOfDataValue <= minMaxValues[1];
};
/* Set the form values and also allow modification of "push" of fields to lower projects/reporting packages/etc.
 */
export function MetaForm(props) {
  let { fields, hideAdmin, meta, setMeta } = props;
  if (!fields || !fields.size) {
    return null;
  }
  let initValues = fields.reduce((acc, field) => {
    let header = field.get("header");
    return acc.set(header, meta.get(header));
  }, Traec.Im.Map());

  let [values, setValues] = useState(initValues);
  let coverageOfDataValue = values.get("Coverage of data");
  let employeeSizeValue = values.get("Please select your company size by number of employees");

  useEffect(() => {
    let currentEmployees = parseFloat(values.get("Number of current employees "));
    let voluntaryLeavers = parseFloat(values.get("Number of Voluntary Leavers"));
    if (!isNaN(currentEmployees) && !isNaN(voluntaryLeavers)) {
      let nEmployees = currentEmployees + voluntaryLeavers;
      setValues(values.set("Coverage of data", nEmployees));
      setMeta(meta.set("Coverage of data", nEmployees));
    } else {
      setValues(values.set("Coverage of data", null));
      setMeta(meta.set("Coverage of data", null));
    }
  }, [values]);

  let isValid = isEmployeeSizeDataValid(coverageOfDataValue, employeeSizeValue);

  let formFields = fields.map((field, i) => (
    <MetaFormField values={values} setValues={setValues} key={i} index={i} field={field} {...props} />
  ));
  return (
    <React.Fragment>
      {hideAdmin ? null : <hr />}
      {formFields}
      {!isValid ? (
        <p style={{ color: "red" }}>
          Coverage of data is the number of employees in your organisation. It must be within the range selected in the
          Company Size question.
        </p>
      ) : null}
    </React.Fragment>
  );
}

export function MetaSaveButton({ hide, pending, onSaveHandler, disabled }) {
  if (hide) {
    return null;
  }
  let text = pending ? <div className="spinner-border spinner-border-sm text-light" role="status" /> : "Save";
  return (
    <React.Fragment>
      <hr />
      <BSBtn text={text} onClick={onSaveHandler} disabled={disabled || pending} />
    </React.Fragment>
  );
}

/* Set values, modify and push fields for meta-data */
export function SetMetaDataFields({
  hide,
  hideAdmin,
  hideSave,
  saveOnBlur,
  saveMetaFetchProps,
  pushMetaFetchProps,
  metaJson,
  setMeta,
  disabled
}) {
  if (hide) {
    return null;
  }

  // Ensure that we have something to start with for the meta-data
  metaJson = Traec.Im.fromJS(metaJson || Traec.Im.Map());
  console.log("SetMetaDataFields", Traec.Im.isImmutable(metaJson) ? metaJson.toJS() : metaJson);

  const [_meta, _setMeta] = useState(metaJson);
  const initFields = metaJson.getInPath("input_details.fields") || Traec.Im.List()
  const [fields, setFields] = useState(initFields);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setFields(metaJson.getInPath("input_details.fields") || Traec.Im.List());
    setMeta ? setMeta(metaJson) : _setMeta(metaJson);
  }, [metaJson]);

  const onSaveHandler = e => {
    e.preventDefault();
    setPending(true);
    let meta = setMeta ? metaJson : _meta;

    let __meta = fields
      .reduce((acc, cur) => {
        let key = cur.get("header");
        return acc.set(key, meta.get(key));
      }, Traec.Im.Map())
      .set("input_details", {
        fields: fields.toJS()
      });

    // Find fields that have been deleted
    let deletedFields = Traec.Im.Set(initFields.map(i => i.get("header")))
      .subtract(fields?.map(i => i.get("header")))

    // Set meta-data field values for deleted fields to null
    for (let fieldName of deletedFields) {
      __meta = __meta.set(fieldName, null)
    }

    saveMeta(saveMetaFetchProps, __meta.toJS(), setPending);
  };

  const pushHandler = (e, fieldName, includeValue) => {
    e.preventDefault();
    pushMeta(pushMetaFetchProps, fieldName, includeValue);
  };

  console.log("AAAAA calling MetaForm", fields?.toJS());
  return (
    <ErrorBoundary>
      <AddFormField hide={hideAdmin} fields={fields} setFields={setFields} />
      <MetaForm
        hideAdmin={hideAdmin}
        saveOnBlur={saveOnBlur}
        fields={fields}
        setFields={setFields}
        meta={setMeta ? metaJson : _meta} // Use local state if a setMeta function is not provided
        setMeta={setMeta ? setMeta : _setMeta}
        pushHandler={pushMetaFetchProps ? pushHandler : null}
        onSaveHandler={onSaveHandler}
        disabled={disabled}
      />
      <MetaSaveButton hide={hideSave} pending={pending} onSaveHandler={onSaveHandler} disabled={disabled} />
    </ErrorBoundary>
  );
}
